import { isEmpty } from 'lodash';
import Stripe from 'stripe';

import { SubscriptionPlan, SubscriptionPrice } from '@/types';
import { SimpleInvoiceApiInterface } from '@/types/api-types';
import { formatPrice } from '@/utils/format';

// Gets the default plans from a list
export const getDefaultSubscriptionPlan = (plans: SubscriptionPlan[]) => {
  return plans.find((plan) => plan.metadata?.isDefault);
};

// Find a plan by its ID
export const findPlanById = (planId: string, plans: SubscriptionPlan[]) => {
  return plans.find((plan) => plan.id === planId);
};

// Gets the plan inclusions
export const getPlanInclusions = (plan: SubscriptionPlan) => {
  const { inclusions } = plan.metadata || {};
  return inclusions ? JSON.parse(inclusions) : null;
};

// Gets the Plan Default Price
export const getPlanPrice = (plan?: SubscriptionPlan | null, getYearPrice?: boolean): SubscriptionPrice | null => {
  if (getYearPrice) {
    // If year price is requested, get that if it exists
    const yearPrice = plan?.planPrices?.find((price) => price.interval === 'year');
    if (yearPrice) return yearPrice;
  }
  // Else just use default (monthly) price
  const defaultPrice = plan?.planPrices?.find((price) => price.interval === 'month');
  return defaultPrice || null;
};

// Get plan price as string
export const getPlanPriceString = (price: SubscriptionPrice, getYearPrice?: boolean): string => {
  const isFreePlan = price.unitAmount === 0;
  if (isFreePlan) return 'Free';

  const unitAmount = getYearPrice && price.unitAmount ? price.unitAmount / 12 : price.unitAmount || 0;
  return `${formatPrice(unitAmount, price.currency || 'usd')}`;
};

export const getPlanIntervalString = (price: SubscriptionPrice, getYearPrice?: boolean): string => {
  const isFreePlan = price.unitAmount === 0;
  if (isFreePlan) return 'No credit card required';
  const baseString = 'per month';

  const { unitAmount = 0, currency = 'usd' } = price;
  if (getYearPrice) {
    return `${baseString}, billed at ${formatPrice(unitAmount, currency)}/year`;
  }

  return baseString;
};

// If Free plan?
export const checkIsFreePlan = (plan: SubscriptionPlan): boolean => {
  const monthlyPrice = getPlanPrice(plan);
  return monthlyPrice?.unitAmount === 0;
};

export const checkIsTeamPlan = (plan: SubscriptionPlan): boolean => {
  return plan.name === 'Team' || plan.metadata?.sku === 'XX1-02-01';
};

// Is plan a primary plan?
export const checkIsPrimaryPlan = (plan: SubscriptionPlan): boolean => {
  const { isPrimary } = plan.metadata || {};
  return isPrimary === 'true';
};

// Returns only Primary Plans
export const filterPrimaryPlans = (plans: SubscriptionPlan[]): SubscriptionPlan[] => {
  if (isEmpty(plans)) return plans;
  return plans.filter((plan) => checkIsPrimaryPlan(plan));
};

/**
 * Converts massive stripe invoice into a simple one
 * @param invoices - Stripe Invoices array
 * @returns Simple Invoice
 */
export const transformStripeInvoices = (invoices: Stripe.Invoice[]): SimpleInvoiceApiInterface[] => {
  return invoices.map((invoice) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, total, currency, invoice_pdf, status, lines, created } = invoice;
    const lineDescription = lines.data[0]?.description || '';

    return {
      id,
      total,
      currency,
      invoicePdf: invoice_pdf,
      invoiceDate: new Date(created * 1000).toISOString(),
      status,
      description: lineDescription,
    } as SimpleInvoiceApiInterface;
  });
};
