import React from 'react';

import { planItemStyles } from '@/components/modules/plan/item';
import { Skeleton } from '@/components/ui/loading/skeleton';
import { tv } from '@/utils/styles';

export interface PlanSkeletonProps {
  showInclusions?: boolean;
}

const PlanSkeleton = ({ showInclusions }: PlanSkeletonProps) => {
  const { stack } = styles();
  const { base } = planItemStyles();

  return (
    <div className={base({ className: 'gap-8' })}>
      <div className={stack()}>
        <Skeleton variant="heading" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>

      <div className={stack()}>
        <Skeleton variant="heading" />
        <Skeleton variant="text" />
      </div>

      <div className={stack()}>
        <Skeleton variant="button" />
      </div>

      {showInclusions && (
        <div className={stack()}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    stack: 'flex flex-col gap-2',
  },
});

export { PlanSkeleton };
