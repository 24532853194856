import { OffsetOptions } from '@floating-ui/react';
import React from 'react';

import { Icon } from '@/components/icon';
import { TooltipContent } from '@/components/tooltip/tooltip-content';
import { TooltipProvider } from '@/components/tooltip/tooltip-provider';
import { TooltipTrigger } from '@/components/tooltip/tooltip-trigger';
import { tv } from '@/utils/styles';

export interface PromoTextProps {
  parent: React.ReactNode;
  label: string;
  className?: string;
  offset?: OffsetOptions;
  variant?: 'white';
}

const PromoText = ({ label, className, parent, offset, variant }: PromoTextProps) => {
  const { base, labelStyles, icon } = styles({ variant });
  return (
    <TooltipProvider placement="top" isOpen offset={offset} shouldFlip={false}>
      <TooltipTrigger asChild>{parent}</TooltipTrigger>
      <TooltipContent variant="basic">
        <div className={base({ className })}>
          <span className={labelStyles()}>{label}</span>
          <Icon className={icon()} name="arrow-drawn" color="red" />
        </div>
      </TooltipContent>
    </TooltipProvider>
  );
};

const styles = tv({
  slots: {
    base: 'relative flex rotate-2 text-lightBlue-600 [&_svg]:fill-lightBlue-600',
    labelStyles: 'font-casual text-base',
    icon: 'absolute left-full top-0.5 pl-2',
  },
  variants: {
    variant: {
      white: {
        base: 'relative flex rotate-2 text-white/80 [&_svg]:fill-white',
      },
    },
  },
});

export { PromoText };
