import useSWRImmutable from 'swr/immutable';

import { SubscriptionPlan } from '@/types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { getPlansUrl } from '@/utils/helpers/api-urls';

interface SubscriptionPlansState extends AsyncDataInterface {
  plans: SubscriptionPlan[];
}

export interface PlansApiResponse {
  plans: SubscriptionPlan[];
}

const ERROR_MESSAGE = 'Error fetching subscription plans';

export const useSubscriptionPlans = (): SubscriptionPlansState => {
  const { data, isLoading, error } = useSWRImmutable<PlansApiResponse>(getPlansUrl, request);

  const errorMessage = error ? ERROR_MESSAGE : undefined;

  return { plans: data?.plans || [], isLoading, error: errorMessage };
};
