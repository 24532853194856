import { VariantProps } from 'tailwind-variants';

import { tv } from '@/utils/styles';

type SkeletonVariants = VariantProps<typeof styles>;

export interface SkeletonProps {
  variant?: SkeletonVariants['type'];
}

const Skeleton = ({ className, variant, ...props }: React.HTMLAttributes<HTMLDivElement> & SkeletonProps) => {
  return <div className={styles({ className, type: variant })} {...props} />;
};

const styles = tv({
  base: 'animate-pulse rounded-md bg-grey-200',
  variants: {
    type: {
      text: 'h-4 w-full',
      heading: 'h-6 w-1/2',
      button: 'h-12 w-3/4',
    },
  },
});

export { Skeleton };
